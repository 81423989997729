import { css } from "styled-components";
import breakpoint from "./breakpoint";
import colors from "./colors";

const texts = css`
  * {
    font-family: "NotoSansThai", "sans-serif" !important;
  }

  mark {
    font-size: inherit;
    background: transparent !important;
    color: ${colors.lime["200"]};
  }

  a {
    cursor: pointer;
  }

  h1 {
    font-size: 2.4rem;
    font-weight: 500;

    @media (min-width: ${breakpoint.sm}) {
      font-size: 3rem;
    }

    @media (min-width: ${breakpoint.md}) {
      font-size: 4rem;
    }

    @media (min-width: ${breakpoint["2xl"]}) {
      font-size: 5rem;
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 500;

    @media (min-width: ${breakpoint.sm}) {
      font-size: 2rem;
    }

    @media (min-width: ${breakpoint.md}) {
      font-size: 3rem;
    }

    @media (min-width: ${breakpoint["2xl"]}) {
      font-size: 4rem;
    }
  }

  h3 {
    font-size: 2rem;
    font-weight: 500;

    @media (min-width: ${breakpoint.sm}) {
      font-size: 2rem;
    }

    @media (min-width: ${breakpoint.md}) {
      font-size: 3rem;
    }

    @media (min-width: ${breakpoint["2xl"]}) {
      font-size: 4rem;
    }
  }

  h4 {
    font-size: 1.2rem;

    @media (min-width: ${breakpoint.sm}) {
      font-size: 1.5rem;
    }

    @media (min-width: ${breakpoint.md}) {
      font-size: 2rem;
    }

    @media (min-width: ${breakpoint["2xl"]}) {
      font-size: 2.5rem;
    }
  }

  h6 {
    font-size: 1rem;

    @media (min-width: ${breakpoint.sm}) {
      font-size: 1.125rem;
    }

    @media (min-width: ${breakpoint.md}) {
      font-size: 1.25rem;
    }

    @media (min-width: ${breakpoint["2xl"]}) {
      font-size: 2rem;
    }
  }
`;

export default texts;
