import { createGlobalStyle } from "styled-components";
import colors from "./colors";
import texts from "./texts";
import buttons from "./buttons";
import input from "./input";

const GlobalStyle = createGlobalStyle`
  ${texts}

  html, body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: calc(100vw - (100vw - 100%)); //? This will make page calculate 100vw without calculate scrollbar width
    font-size: 16px;
  }
  
  body {
    background-color: ${colors.gray["200"]};
  }

  #__gatsby {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }

  ${buttons}

  ${input}

  .swal2-container {
    z-index: 1200;
  }

  .clickable {
    &:not(:disabled) {
      cursor: pointer;
    }
    &:active:not(:disabled) {
      transform: scale(90%);
      transition: transform 0.3s ease-in-out;
    }
  }
`;

export default GlobalStyle;
