import { css } from "styled-components";
import colors from "./colors";
import breakpoint from "./breakpoint";

const buttons = css`
  button {
    padding: 0.875rem 1.125rem;
    background-color: ${colors.green["200"]};
    border: 1px solid ${colors.green["200"]};
    color: ${colors.white};
    border-radius: 0.375rem;
    font-weight: 500;
    font-size: 11px;

    @media (min-width: ${breakpoint.xs}) {
      font-size: 1rem;
    }

    &.white-outlined {
      color: ${colors.white};
      background-color: transparent;
      border-color: ${colors.white};
    }

    &:disabled {
      filter: grayscale(1);
    }
  }

  button:active:not(:disabled) {
    transform: scale(90%);
    transition: transform 0.3s ease-in-out;
  }
`;

export default buttons;
