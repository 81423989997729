const colors = {
  transparent: "transparent",
  green: {
    50: "#EAFFEA",
    100: "#6FCF97",
    200: "#10C56C",
    300: "#00BF63",
    400: "#00C359",
    500: "#107342",
    600: "#006C31",
    700: "#026937",
    800: "#133240",
  },
  lime: {
    100: "#86DB62",
    200: "#7ED957",
    300: "#9BD609",
  },
  gray: {
    100: "#F5F5F5",
    200: "#FAFAFA",
    300: "#E7EAEC",
    500: "#A1ADB3",
    700: "#666666",
    900: "#212121",
  },
  greenAlpha: {
    100: "#9BD6094D",
    200: "#86DB6233",
    300: "#10C56C33",
  },
  greenGradient: {
    start: "#7ED957",
    end: "#00C359",
  },
  whiteGreen: {
    start: "#EAFFEA",
    end: "#1F6670",
  },
  transparentGreen: {
    start: "#00000000",
    end: "#20363C",
  },
  white: "#FFFFFF",
  black: "#000000",
  blackAlpha: {
    23: "#0000003B",
    54: "#0000008A",
    40: "#00000066",
    50: "#00000080",
    60: "#00000066",
    70: "#000000B2",
  },
};

module.exports = colors;
