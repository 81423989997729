import { css } from "styled-components";
import colors from "./colors";

const input = css`
  input,
  textarea {
    text-overflow: ellipsis;
    padding: 1rem 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid ${colors.blackAlpha[23]};
  }

  input:focus-visible {
    outline: none;
  }
`;

export default input;
